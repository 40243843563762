export default {
    general: {
        signInWithGoogleButton: 'Mit Google anmelden',
        chooseValueLabel: 'Wert wählen',
    },

    grid: {
        pagination: {
            prev: 'Vorherige',
            next: 'Weiter',
            page: 'Seite',
            of: 'von',
        },
    },
}
